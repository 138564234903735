.motto-brand-mint {
  display: inline;
  font-family: "Oswald";
  font-style: normal;
  font-weight: 600;
  font-size: 4vw;
  line-height: 1.5vw;
  text-transform: uppercase;
  /* color: transparent;
      -webkit-text-stroke: 0.1px white; */
  /* border: 1px solid #ffffff; */
  color: transparent;
  -webkit-text-stroke-width: 0.1px;
  -webkit-text-stroke-color: white;
  margin-top: 1.5vw;
}
