.add-nft {
  line-height: 19px;
  background-color: theme-color("site-green-light");
  color: theme-color("site-primary") !important;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 5px;
  height: min-content !important;
}

.collection-tile {
  min-width: 215px;
  max-width: 18rem;
  min-height: 385px;
  padding: 50px 30px 50px 30px;
  border: gray;
  border-style: dashed;
  border-radius: 20px;
  text-align: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
