.background-main {
  background: url("../assets/img/Home/Background.webp");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  overflow: hidden;
  height: fit-content;
  /* height: 100%; */
  /* height: 543vh; */
  /* background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 131rem; */
}
.fixed-top-offset{
  position: fixed;
  top: 30;
  right: 0;
  left: 0;
  z-index: 1030;
  transition: top 0.3s ease-in-out; /* Add transition for smooth movement */

}
.navbarRow {
  display: flex;
  justify-content: flex-end;
}
.navbar__button {
  display: flex !important;
  align-items: center;
  justify-content: center;
  margin: 2vw;
  font-size: 3vw !important;
  font-family: "Oswald";
  height: 7vw;
  width: 15vw;
  /* background-color: #4c48498f !important; */
  background-color: transparent !important;
  border: 1px solid white !important;
  box-shadow: 3px 3px 2px #87bba2;
}
  /* .wallet-adapter-button, .wallet-adapter-button-trigger{
    all:unset !important;
  } */

/* .wallet-adapter-button  {
  display: flex !important;
  align-items: center;
  justify-content: center;
  margin: 2vw !important;
  font-size: 2.5vw !important;
  font-family: "Oswald" !important;
  height: 7vw !important;
  width: 15vw !important;
  background-color: #4c48498f !important;
  background-color: transparent !important;
  border: 1px solid white !important;
  box-shadow: 3px 3px 2px #87bba2;
} */
.navbar__button:hover {
  /* background-image: linear-gradient(144deg, #af40ff, #5b42f3 50%, #00ddeb); */
  background-image: linear-gradient(144deg, #173b41, #31534c 50%, #46604c);
}

.carousel-height {
  height: 600px !important;
}

.mottoNFT {
  position: relative;
  left: -15px;
}
.mottoNFT__img {
  /* position: absolute;
    left: 0px;
    top: -13px; */
  width: 55%;
  height: auto;
}
.mottoNFT__brand {
  font-family: "Oswald";
  font-style: normal;
  font-weight: 600;
  font-size: 6vw;
  /* line-height: 16vw; */
  /* margin: 1rem; */
  text-transform: uppercase;
  color: transparent;
  -webkit-text-stroke-width: 0.1px;
  -webkit-text-stroke-color: white;
}
.mottoQuestionText {
  margin-top: 20vw;
}
.mottoQuestionText__1 {
  font-family: "Oswald";
  font-style: normal;
  font-weight: 500;
  font-size: 8vw;
  line-height: 5vw;
  /* margin: 1rem; */
  text-transform: uppercase;
  color: #ffffff;
}
.mottoQuestionText__2 {
  font-family: "Oswald";
  font-style: normal;
  font-weight: 600;
  font-size: 8vw;
  line-height: 16vw;
  /* margin: 1rem; */
  text-transform: uppercase;
  color: #ffffff;
}

.mottoDescription {
  /* margin-left: 5vw; */
  font-family: "Rajdhani";
  font-style: normal;
  font-weight: 500;
  font-size: 2vw;
  line-height: 2vw;
  text-align: right;
  text-transform: uppercase;
  color: #ffffff;
}
.startNowText {
  height: 8vw;
  width: 97.8vw;
  /* padding: 2vw; */
  border: 0.5px white;
  border-style: solid none;
  align-items: center;
  justify-content: center;
  margin-top: 3vw;
}
.startNowText__1 {
  display: inline;
  font-family: "Oswald";
  font-style: normal;
  font-weight: 600;
  font-size: 6vw;
  line-height: 7vw;
  text-transform: uppercase;
  color: #ffffff;
}
.startNowText__2 {
  display: inline;
  font-family: "Oswald";
  font-style: normal;
  font-weight: 600;
  font-size: 6vw;
  line-height: 7vw;
  text-transform: uppercase;
  /* color: #ffffff; */
  /* color: transparent;
    -webkit-text-stroke: 0.1px white; */
  /* border: 1px solid #ffffff; */
  color: transparent;
  -webkit-text-stroke-width: 0.1px;
  -webkit-text-stroke-color: white;
}
.silverMoto__logo__text {
  display: inline;
  font-family: "Oswald";
  font-style: normal;
  font-weight: 600;
  font-size: 9vw;
  line-height: 5vw;
  text-transform: uppercase;
  /* color: transparent;
    -webkit-text-stroke: 0.1px white; */
  /* border: 1px solid #ffffff; */
  color: transparent;
  -webkit-text-stroke-width: 0.1px;
  -webkit-text-stroke-color: white;
  margin: 10vw;
  margin-top: 17vw;
}
.silverMoto__logo__description {
  margin-left: 5vw;
  font-family: "Rajdhani";
  font-style: normal;
  font-weight: 500;
  font-size: 2vw;
  line-height: 2vw;
  text-transform: uppercase;
  color: #ffffff;
}

.silverMotto__heading {
  text-align: end;
  margin-top: 6vw;
}
.silverMotto__heading__1 {
  display: inline;
  font-family: "Oswald";
  font-style: normal;
  font-weight: 600;
  font-size: 5vw;
  /* line-height: 5vw; */
  text-transform: uppercase;
  color: #ffffff;
}
.silverMotto__heading__2 {
  display: inline;
  font-family: "Oswald";
  font-style: normal;
  font-weight: 600;
  font-size: 5vw;
  /* line-height: 5vw; */
  text-transform: uppercase;
  /* color: transparent;
    -webkit-text-stroke: 0.1px white; */
  /* border: 1px solid #ffffff; */
  color: transparent;
  -webkit-text-stroke-width: 0.1px;
  -webkit-text-stroke-color: white;
}
.pricingBar {
  height: 6vw;
  width: 100vw;
  /* padding: 2vw; */
  border: 0.5px white;
  border-style: solid none;
  align-items: center;
  justify-content: center;
  margin-top: 2vw;
}
.pricingBar__text {
  font-family: "Rajdhani";
  font-style: normal;
  font-weight: 400;
  font-size: 2.5vw;
  color: #ffffff;
  text-transform: uppercase;
  margin: 2vw 3vw;
  display: inline;
}

.pricingBar__img {
  width: 2.2vw;
  height: 2.2vw;
  display: inline;
  margin-bottom: 0.5vw;
}
.pricingBar__question {
  color: #ffffff;
  font-size: 1vw;
}
.uniqueMotto {
  border-bottom: 0.5px solid white;
  padding-top: 6vw;
  padding-bottom: 2.5vw;
}
.uniqueMottoDescription {
  font-family: "Rajdhani";
  font-style: normal;
  font-weight: 500;
  font-size: 2vw;
  line-height: 2vw;
  text-transform: uppercase;
  color: #ffffff;
  text-align: center;
}

.charity {
  margin: 10vw 0vw;
}
.charityLogoImg {
  width: 34.2vw;
  height: 18.2vw;
}
.charityHeader {
  display: inline;
  font-family: "Oswald";
  font-style: normal;
  font-weight: 600;
  font-size: 6vw;
  text-transform: uppercase;
  color: transparent;
  -webkit-text-stroke-width: 0.1px;
  -webkit-text-stroke-color: white;
  text-align: right;
}
.charityHeader__row {
  margin-bottom: 5vw;
}
.charityDescription {
  margin-left: 5vw;
  font-family: "Rajdhani";
  font-style: normal;
  font-weight: 500;
  font-size: 2vw;
  line-height: 2vw;
  text-align: right;
  text-transform: uppercase;
  color: #ffffff;
}
.ticker {
  width: 100%;
  height: 10vw !important;
}
.Accordians {
  margin-top: 7vw;
}
.Accordian__content {
  margin-top: 5vw;
  margin-left: 5vw;
  font-family: "Rajdhani";
  font-style: normal;
  font-weight: 600;
  font-size: 2.5vw;
  line-height: 1vw;
  text-transform: uppercase;
  color: #ffffff;
  border-bottom: 0.5px solid #ffffff;
  cursor: pointer;
}
.Accordian__content__open {
  /* margin: 2vw 0vw; */
  font-family: "Rajdhani";
  font-style: normal;
  font-weight: 500;
  font-size: 2vw;
  line-height: 2vw;
  text-transform: uppercase;
  color: #ffffff;
}
.specs {
  margin-top: 10vw;
}
.specs__header {
  display: inline;
  font-family: "Oswald";
  font-style: normal;
  font-weight: 600;
  font-size: 5vw;
  /* line-height: 5vw; */
  text-transform: uppercase;
  color: #ffffff;
}
.specs__description__1 {
  font-family: "Rajdhani";
  font-style: normal;
  font-weight: 500;
  font-size: 1.7vw;
  line-height: 2vw;
  text-transform: uppercase;
  color: #ffffff;
}
.specs-nftLogo {
  display: flex;
  align-items: center;
  justify-content: center;
}
.specs-nftLogo img {
  width: 25.2vw;
  height: 32.2vw;
}
.specs-nftTextLogo__row {
  margin-top: 5vw;
}
.specs-nftTextLogo img {
  width: 21.2vw;
  height: 51.2vw;
}

.fallback {
  margin-top: 12vw;
}
.fallback__heading_text {
  display: inline;
  font-family: "Oswald";
  font-style: normal;
  font-weight: 600;
  font-size: 3vw;
  text-transform: uppercase;
  color: #ffffff;
  text-align: center;
}
.fallback__description_text {
  font-family: "Rajdhani";
  font-style: normal;
  font-weight: 500;
  font-size: 1.7vw;
  line-height: 2vw;
  text-transform: uppercase;
  color: #ffffff;
  text-align: center;
}
.linkWallet {
  margin-top: 2vw;
  margin-bottom: 10vw;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.5px solid #ffffff;
  padding: 1.5vw;
}
.linkWallet:hover {
  cursor: pointer;
}
.linkWallet p {
  font-family: "Rajdhani";
  font-style: normal;
  font-weight: 700;
  font-size: 1.7vw;
  line-height: 2vw;
  text-transform: uppercase;
  color: #ffffff;
  text-align: center;
  margin-bottom: 0;
}

.glassdoor-background{
  backdrop-filter: blur(15px);
  transition: top 0.3s ease-in-out; /* Add transition for smooth movement */

}

@media screen and (min-width: 1441px) {
  .navbar__button {
    display: flex !important;
    align-items: center;
    justify-content: center;
    margin: 25px;
    font-size: 21px !important;
    height: 60px;
    width: 130px;
    /* background-color: transparent !important; */
  }
}
@media screen and (min-width: 1025px) and (max-device-width: 1440px) {
  .navbar__button {
    display: flex !important;
    align-items: center;
    justify-content: center;
    margin: 20px;
    font-size: 18px !important;
    height: 50px;
    width: 110px;
    /* background-color: transparent !important; */
  }
}
@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .navbar__button {
    /* display: flex !important;
    align-items: center;
    justify-content: center; */
    margin: 18px;
    font-size: 17px !important;
    height: 45px;
    width: 100px;
    /* background-color: transparent !important; */
  }
  .mottoQuestionText {
    margin-top: 35vw;
  }
}

@media screen and (min-device-width: 480px) and (max-device-width: 767px) {
  .navbar__button {
    display: flex !important;
    align-items: center;
    justify-content: center;
    margin: 13px;
    font-size: 12px !important;
    height: 30px;
    width: 80px;
    /* background-color: transparent !important; */
  }
  .mottoQuestionText {
    margin-top: 35vw;
  }
}

@media screen and (min-device-width: 300px) and (max-device-width: 480px) {
  .mottoQuestionText {
    margin-top: 35vw;
  }
}

.btn-back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1;
  display: none;
  background-color: #4c48498f !important;
  box-shadow: 3px 3px 2px #87bba2;
}

.collections-text-modal {
  display: inline;
  font-family: "Oswald";
  font-style: normal;
  font-weight: 600;
  font-size: 50px;
  text-transform: uppercase;
  color: #ffffff;
  /* color: transparent;
    -webkit-text-stroke: 0.1px white; */
  /* border: 1px solid #ffffff; */
  /* color: transparent;
  -webkit-text-stroke-width: 0.1px;
  -webkit-text-stroke-color: white; */
}
.enter-motto-text {
  display: inline;
  font-family: "Oswald";
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  text-transform: uppercase;
  color: #ffffff;
}
.close-button-modal {
  color: #ffffff;
  font-size: large;
}
.close-button-modal:hover {
  color: #333030;
  cursor: pointer;
}

.stepClasses {
  color: blue !important;
  background-color: white !important;
}
.stepper-main {
  font-family: "Rajdhani";
  font-style: normal;
  font-weight: 500;
  font-size: 1.7vw;
  line-height: 2vw;
  text-transform: uppercase;
  color: #ffffff;
}
.circle-div {
  width: 100px; /* sets the width of the element */
  height: 100px; /* sets the height of the element */
  background-color: white;
  border-radius: 50%;
  display: flex; /* centers the inner element horizontally and vertically */
  align-items: center;
  justify-content: center;
}
.circle-number {
  color: white;
  font-size: 48px;
}

.telegram-banner {
  background-color: #229ED9;  /* Dark gray, adjust as needed */
  color: white;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* font-size: 0.9rem; */
}
/* Small screens (e.g., smartphones) */
@media (max-width: 600px) {
  .telegram-banner {
    font-size: 14px;
  }
}

/* Medium screens (e.g., tablets) */
@media (min-width: 601px) and (max-width: 1024px) {
  .telegram-banner {
    font-size: 17px;
  }
}

/* Large screens (e.g., desktops) */
@media (min-width: 1025px) {
  .telegram-banner {
    font-size: 19px
  }
}
.telegram-link {
  color: white;
  text-decoration: none;
  margin: 0px !important;
  margin-left: 5px;
}
.telegram-header-text{
  color: white;
  font-weight: bold;
}
.telegram-icon {
  margin-right: 5px;
}

.footer {
  /* background-color: #222; */
  color: #ccc;  /* Light gray text */
  padding: 20px;
  text-align: center;
  font-size: 0.9rem;
}

.footer a {
  color: #fff;  /* White text for links */
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}