@import url("https://fonts.googleapis.com/css?family=Oswald|Rajdhani&display=swap");
/* svg {
  font: bold 70px Century Gothic, Arial;
  text-transform: uppercase;
  width: 50%;
  height: auto;
}

text {
  fill: none;
  stroke: white;
  stroke-width: 0.5px;
  stroke-linejoin: round;
  animation       : 2s pulsate infinite;
}
header,
footer {
  height: 100px;
  font-size: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #212121;
  color: #ffffff;
} */
.nav-item {
  /* width: 200px; */
}
.nav-item:hover {
  cursor: pointer;
}

.gift-card-carousel .react-multi-carousel-item {
  padding: 20px 45px !important;
}
.gift-card-carousel .custom-button-group {
  position: absolute !important;
  width: 100%;
  display: flex;
}
.gift-card-carousel .custom-button-group button {
  background: none !important;
  border: none !important;
}
.gift-card-carousel .custom-button-group .btn-left {
  margin-right: auto !important;
}
.gift-card-carousel .custom-button-group .btn-right {
  margin-left: auto !important;
}
.position-absolute {
  position: absolute !important;
}

.giftCard {
  border-radius: 10px;
  background-color: #2c2a29;
  position: relative;
  padding: 30px;
  margin-top: 220px;
  height: 230px;
  display: flex;
  flex-direction: column;
}
.giftCard__btn {
  left: 30px;
  top: 90%;
  width: calc(100% - 60px);
}
.giftCard__color {
  top: -90%;
  right: -1px;
}
.giftCard__image {
  top: -87%;
  z-index: 9;
  right: -10px;
  transform: rotate(44deg);
  width: 100%;
  height: 77%;
  padding-left: 30px;
}
.giftCard__control {
  width: 60px;
  height: 100px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.homepage-modal {
  background-image: linear-gradient(
    144deg,
    rgb(60 110 118),
    #70a197 40%,
    #46604c
  ) !important;
}
